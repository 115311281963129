import { actions } from "../../store/actions";
import { store } from "../../store";
import { getInitialScoreState } from "../../common/utils";
import { cloneDeep } from "lodash";

/* Get test data from test file */
export const getTestData = (
    event_id,
    loadingFlag,
    sport,
    setMessage,
    setNewCommand,
    setDbData,
    setInternalData,
    setLoadingFlag,
) => {
    const config = store.getState()["common"].config;
    let test_data: any;
    if (config.REACT_APP_ENV && config.REACT_APP_ENV !== "PROD") {
        fetch("./test_events/" + sport + ".json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                test_data = Object.assign(myJson);
                store.dispatch(actions[event_id].setEventInfo(test_data));
                store.dispatch(
                    actions[event_id].setScore(
                        getInitialScoreState(test_data?.sets || 9),
                    ),
                );
                connect(
                    event_id,
                    loadingFlag,
                    sport,
                    setMessage,
                    setNewCommand,
                    setDbData,
                    setInternalData,
                    setLoadingFlag,
                );
            });
    } else {
        store.dispatch(actions[event_id].setEventInfo(false));
    }
};

export const connect = (event_id, loadingFlag, sport, setMessage, setNewCommand, setDbData, setInternalData, setLoadingFlag) => {

    const { config, username, token } = store.getState()["common"];
    const url = "?page=monitor&event_id=" + event_id + "&sport=" + sport + '&user=' + username + '&token=' + token;
    const websocket_url = config.REACT_APP_WEBSOCKET_URL + url;
    const socket = new WebSocket(websocket_url);
    store.dispatch(actions[event_id].setSocket(socket));

    socket.onopen = () => {
        console.log("connected");
        store.dispatch(actions["common"].setConnectionStatus(true));
        let obj = { type: "load", value: {} };
        try {
            socket.send(JSON.stringify(obj)); //send data to the server
        } catch (error) {
            console.log(error); // catch error
        }
    };

    socket.onmessage = (evt: { data: string }) => {
        // console.log('onmessage');
        let message;
        // listen to data sent from the websocket server
        try {
            message = JSON.parse(evt.data);
        } catch (e) {
            console.log(e); // error in the above string (in this case, yes)!
        }

        if (
            message?.type !== "internal" &&
            event_id !== "testUnit" &&
            event_id !== "unitTest"
        ) {
            console.log("onmessage ", message);
        }

        setMessage(message);

        switch (message.type) {
            case "load":
                store.dispatch(actions["common"].setUser(message.user));
                if (!loadingFlag) {
                    //socketId = message.num
                    if (typeof message.value !== "undefined") {
                        if (message.value !== false) {
                            message.value = message.value.split("\n");
                        } else {
                            message.value = [];
                        }
                    }

                    if (
                        !event_id.startsWith("test") &&
                        message.eventInfo &&
                        message.eventInfo.reporter
                    ) {
                        store.dispatch(
                            actions[event_id].setEventInfo(
                                message?.eventInfo?.reporter,
                            ),
                        );
                        store.dispatch(
                            actions[event_id].setScore(
                                getInitialScoreState(
                                    message?.eventInfo?.reporter?.sets || 9,
                                ),
                            ),
                        );
                    }

                    setLoadingFlag(true);
                    setDbData(message.value);

                    if (typeof message.internal !== "undefined") {
                        if (message.internal !== false) {
                            message.internal = message.internal.split("\n");
                        } else {
                            message.internal = [];
                        }
                    }

                    if (message.eventInfo && message.eventInfo === "error") {
                        store.dispatch(actions[event_id].setEventInfo(false));
                    }

                    setInternalData(message.internal);
                }
                break;

            case "command":
                if (message.user) {
                    message.value["user"] = cloneDeep(message.user);
                }
                setNewCommand([JSON.stringify(message)]);
                break;
            case "reset":
                setNewCommand([]);
                setInternalData([]);
                //dispatch(actions[event_id].setMessageType('reset'))
                break;
            case "internal":
                setInternalData([JSON.stringify(message)]);
                break;

            case "setting":
                /* Update callcenter lost state */
                setNewCommand([JSON.stringify(message)]);
                break;

            default:
                break;
        }
    };

    socket.onclose = (e: { reason: any }) => {
        if (event_id !== "testUnit" && event_id !== "unitTest") {
            console.log(
                "Socket is closed. Reconnect will be attempted in 1 second.",
                e.reason,
            );
        }

        store.dispatch(actions["common"].setConnectionStatus(false));
        setTimeout(function () {
            connect(
                event_id,
                loadingFlag,
                sport,
                setMessage,
                setNewCommand,
                setDbData,
                setInternalData,
                setLoadingFlag,
            );
        }, 1000);
    };

    socket.onerror = (event) => {
        console.error("WebSocket error observed:", event);
        socket.close();
    };
};
