import React, { useEffect } from 'react';
import { splitEventsFromURL } from '../../common/utils';
import MonitoringBox from './MonitoringBox';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/actions';
import { useMonitoringToolStyles } from './MonitoringToolStyles';
import ErrorPage from './ErrorPage';

const MonitoringTool = ({ test }) => {

    const dispatch = useDispatch();
    const classes = useMonitoringToolStyles();
    let events = splitEventsFromURL();
    let monitoringBoxes = []; //Populate number of Grids(matches) will have opened in MT4

    if (events.length && !test) {
        events.forEach(element => {

            dispatch(actions[element.event_id].setSport(element.sport));
            monitoringBoxes.push(
                /* Populating all gridItems (Match boxes) */
                <Grid
                    id={element.event_id}
                    data-cy={"grid-item-" + element.event_id}
                    key={element.event_id}
                    item
                    // xs={3}
                    className={classes.gridItem}
                >
                    <MonitoringBox
                        event_id={element.event_id}
                    />
                </Grid>
            );
            //eslint-disable-next-line
        });
    }

    const setOnline = () => dispatch(actions['common'].setConnectionStatus(true));
    const setOffline = () => dispatch(actions['common'].setConnectionStatus(false));

    useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);
        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        }

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
        // eslint-disable-next-line
    }, []);
    if ((events.length && events[0].event_id !== 'testUnit' && !test) || (test && events.length && events[0].event_id === 'testUnit')) {
        if (events.length > 8) {
            return (
                <ErrorPage value={"You have selected more than 8 matches!"} />
            )
        }
        else {
            return (
                <div className={classes.mt4Wrapper}>
                    {/* Return Grid container containing Grid items populated from monitoringBoxes */}
                    <Grid
                        data-cy="grid-container"
                        data-testid="grid-container"
                        container
                        spacing={1}
                        className={classes.gridContainer}
                    >
                        {monitoringBoxes}
                    </Grid>
                </div>
            );
        }

    } else {
        return (
            <ErrorPage value={"Missing/Wrong url parameters"} />
        )
    }

}

export default MonitoringTool;