import * as React from 'react';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useState } from 'react';
import ReturnPage from './ReturnPage';
import { useSelector } from 'react-redux';
import { selectors } from '../../store/selectors';
import { connect, getTestData } from './MonitoringBox.utils';


const MonitoringBox = ({ event_id }) => {
    const [msg, setMessage] = useState({ type: 'load', value: '' });
    const [newCommand, setNewCommand] = useState([]);
    const [db_data, setDbData] = useState([]);
    const [internalData, setInternalData] = useState([]);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const sport = String(useSelector(selectors[event_id].selectSport));
    const isAuthorized = cloneDeep(useSelector(selectors['common'].selectAuthorized));

    useEffect(() => {
        if (isAuthorized){
            /* get test data from the json file for TEST event */
            if (event_id.startsWith('test')) { 
                getTestData(event_id, loadingFlag, sport, setMessage, setNewCommand, setDbData, setInternalData, setLoadingFlag);
            } else {
                connect(event_id, loadingFlag, sport, setMessage, setNewCommand, setDbData, setInternalData, setLoadingFlag);
            }
        }

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
        // eslint-disable-next-line
    }, [isAuthorized]);

    return (
        /* Return different COMPONENTS depending on the event_info */
        <ReturnPage
            message={msg}
            event_id={event_id}
            db_data={db_data}
            internalData={internalData}
            newCommand={newCommand}
            loadingFlag={loadingFlag}
        />
    )
}

export default MonitoringBox;