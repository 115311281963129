import React, { Suspense } from 'react';
import LoadingScreen from './LoadingScreen';
import { sportsMapping } from '../../common/constants'
import { useSelector } from 'react-redux';
import { selectors } from '../../store/selectors';
import ErrorPage from './ErrorPage';



const ReturnPage = ({ loadingFlag, message, event_id, db_data, internalData, newCommand }) => {
    
    const url = window.location.search;
    const params = new URLSearchParams(url);
    let page = params.get('page');

    const event_info = useSelector(selectors[event_id].selectEventInfo);
    const sport = String(useSelector(selectors[event_id].selectSport));

    if (JSON.stringify(event_info) !== '{}' && event_info !== false && message.type !== 'error' && page === 'monitor') {
        if (loadingFlag) {
            const sportCompData = sportsMapping[sport];
            /* RETURN Box only when commands are loaded!!!  */
            return ( /* Load full HTML Sceleton */
                <Suspense fallback={<LoadingScreen />}>
                    <sportCompData.component
                        event_id={event_id}
                        db_data={db_data}
                        internalData={internalData}
                        newCommand={newCommand}
                    />
                </Suspense>
            );
        }
        /* Show loading screen for the gap */
        else {
            return (
                <LoadingScreen />
            )
        }

    } else if (JSON.stringify(event_info) === '{}' && message.type !== 'error' && page === 'monitor') {
        return ( /* Load loading screen */
            <LoadingScreen />
        );
    } else if (event_info === false && page === 'monitor') {
        return (
            <div>
                <ErrorPage value={'No Event Info Data for event ' + { event_id }} />
            </div>
        );
    } else {
        return (
            <ErrorPage value={"Missing/Wrong url parameters"} /> /*  think this is correct component which should be rendered here! */
            // <ErrorPage value={message.value} />
        );
    }
}

export default ReturnPage;