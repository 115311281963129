import { AnyAction, combineReducers } from "redux";
import { RootState, CommonState } from "./types";
import { actionTypes } from "./actions";
import { splitEventsFromURL } from "../common/utils";

export const initialState: RootState = {
    gameTime: 0,
    isClockRunning: false,
    currentWeather: "",
    currentPreMatchInfo: "",
    currentWind: "",
    currentAttendance: "",
    currentHomeColor1: "",
    currentHomeColor2: "",
    currentAwayColor1: "",
    currentAwayColor2: "",
    currentCloseOpenGame: "status_unsure",
    currentOverlayDisplay: "block",
    currentLogSection: {},
    currentStatus: "Not Started",
    callcenterLost: -1,
    callcenterLostBool: true,
    currentId: 0,
    currentSequence: 0,
    currentMessageType: "",
    sound: true,
    currentUncfScoreCounter: true,
    commandLog: [],
    internalLog: [],
    eventInfo: {},
    sport: '',
    score: {},
    socket: false
};

export const initialCommonState: CommonState = {
    currentConnectionStatus: false,
    user: '',
    authorized: false,
    config: {},
    username: "",
    token: "",
    organizationId: ""
};

export const reducersObj = {};

const eventsArray = splitEventsFromURL();

eventsArray.forEach((element) => {
    let eventId = element.event_id;
    reducersObj[eventId] = (
        state = initialState,
        action: AnyAction,
    ): RootState => {
        switch (action.type) {
            case actionTypes[eventId].setGameTime: {
                return {
                    ...state,
                    gameTime:
                        action.payload > 1
                            ? action.payload
                            : state.isClockRunning && state.gameTime > 0
                            ? state.gameTime - action.payload
                            : state.gameTime,
                };
            }
            case actionTypes[eventId].stopClock: {
                return {
                    ...state,
                    isClockRunning: action.payload,
                };
            }
            case actionTypes[eventId].startClock: {
                return {
                    ...state,
                    isClockRunning: action.payload,
                };
            }
            case actionTypes[eventId].setWeather: {
                return {
                    ...state,
                    currentWeather: action.payload,
                };
            }
            case actionTypes[eventId].setPreMatchInfo: {
                return {
                    ...state,
                    currentPreMatchInfo: action.payload,
                };
            }
            case actionTypes[eventId].setWind: {
                return {
                    ...state,
                    currentWind: action.payload,
                };
            }
            case actionTypes[eventId].setAttendance: {
                return {
                    ...state,
                    currentAttendance: action.payload,
                };
            }
            case actionTypes[eventId].setHomeColor1: {
                return {
                    ...state,
                    currentHomeColor1: action.payload,
                };
            }
            case actionTypes[eventId].setHomeColor2: {
                return {
                    ...state,
                    currentHomeColor2: action.payload,
                };
            }
            case actionTypes[eventId].setAwayColor1: {
                return {
                    ...state,
                    currentAwayColor1: action.payload,
                };
            }
            case actionTypes[eventId].setAwayColor2: {
                return {
                    ...state,
                    currentAwayColor2: action.payload,
                };
            }
            // case actionTypes.setPlayers: {
            //     const { team, type, index, player } = action.payload;

            //     const tempCurrentTeams = [...state.currentTeams];
            //     tempCurrentTeams[team - 1][type][index] = cloneDeep(player);

            //     return {
            //         ...state,
            //         currentTeams: tempCurrentTeams,
            //     };
            // }

            case actionTypes[eventId].setCloseOpenGame: {
                return {
                    ...state,
                    currentCloseOpenGame: action.payload,
                };
            }

            case actionTypes[eventId].setOverlayDisplay: {
                return {
                    ...state,
                    currentOverlayDisplay: action.payload,
                };
            }

            case actionTypes[eventId].setLogSection: {
                /* Take value from payload */
                const { event_id, value } = action.payload;
                /* copy Object */
                let tempObject = JSON.parse(
                    JSON.stringify(state.currentLogSection),
                );
                /* Update the temp object with current value */
                tempObject[event_id] = { event_id: event_id, value: value };
                return {
                    ...state,
                    currentLogSection: tempObject,
                };
            }

            case actionTypes[eventId].setStatus: {
                return {
                    ...state,
                    currentStatus: action.payload,
                };
            }

            case actionTypes[eventId].setCallcenterLost: {
                return {
                    ...state,
                    callcenterLost: action.payload,
                };
            }

            case actionTypes[eventId].setCallcenterLostBool: {
                return {
                    ...state,
                    callcenterLostBool: action.payload,
                };
            }

            case actionTypes[eventId].setId: {
                return {
                    ...state,
                    currentId: action.payload,
                };
            }

            case actionTypes[eventId].setSequence: {
                return {
                    ...state,
                    currentSequence: action.payload,
                };
            }

            case actionTypes[eventId].setMessageType: {
                return {
                    ...state,
                    currentMessageType: action.payload,
                };
            }

            case actionTypes[eventId].setSound: {
                return {
                    ...state,
                    sound: action.payload,
                };
            }

            case actionTypes[eventId].setCommandLog: {
                return {
                    ...state,
                    commandLog: action.payload,
                };
            }

            case actionTypes[eventId].setInternalLog: {
                return {
                    ...state,
                    internalLog: action.payload,
                };
            }

            case actionTypes[eventId].setEventInfo: {
                return {
                    ...state,
                    eventInfo: action.payload,
                };
            }

            case actionTypes[eventId].setSport: {
                return {
                    ...state,
                    sport: action.payload,
                };
            }

            case actionTypes[eventId].setScore: {
                return {
                    ...state,
                    score: action.payload,
                };
            }

            case actionTypes[eventId].setSocket: {
                return {
                    ...state,
                    socket: action.payload,
                };
            }

            case actionTypes[eventId].setUncfScoreCounter: {
                return {
                    ...state,
                    currentUncfScoreCounter: action.payload,
                };
            }

            /* ====================================== */
            /* American Football - sport specific  */

            default:
                return state;
        }
    };
});

export const commonReducer = (
    state = initialCommonState,
    action: AnyAction,
): CommonState => {
    switch (action.type) {
        case actionTypes['common'].setConnectionStatus: {
            return {
                ...state,
                currentConnectionStatus: action.payload,
            };
        }
        case actionTypes['common'].setUser: {
            return {
                ...state,
                user: action.payload,
            };
        }
        case actionTypes['common'].setAuthorized: {
			return {
				...state,
				authorized: true
			};
		}
		case actionTypes['common'].setConfig: {
			return {
				...state,
				config: action.payload
			};
		}

        case actionTypes['common'].setUsername: {
			return {
				...state,
				username: action.payload
			};
		}

		case actionTypes['common'].setAuth0Token: {
			return {
				...state,
				token: action.payload
			};
		}

		case actionTypes['common'].setOrganizationId: {
			return {
				...state,
				organizationId: action.payload
			};
		}

        default:
            return state;
    }
};

export const rootReducer = combineReducers({...reducersObj, 'common' :commonReducer});
