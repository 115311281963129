import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import { useAuth0 } from '@auth0/auth0-react';
import { selectors } from 'store/selectors';
import { actions } from 'store/actions';

// const postRedirectMessage = (url: string): void => {
// 	const message = { 'event_id': 'redirect_message', data: { redirectUrl: url } };
// 	window.parent.postMessage(message, '*');
// };

const displayMessage = evt => {
	if (evt?.data?.data?.redirectUrl) {
		localStorage.setItem('parentUrl', evt?.data?.data?.redirectUrl);
	}
};

window.addEventListener('message', displayMessage, false);

const AuthProvider = ({ children }) => {
	const config = cloneDeep(useSelector(selectors['common'].selectConfig));

	const dispatch = useDispatch();
    const [organizationId, setOrgId] = useState('');

    const { loginWithRedirect, /* Error, */ isAuthenticated, isLoading, user, getAccessTokenSilently,
	/*getAccessTokenWithPopup, getIdTokenClaims, loginWithPopup, logout */ } = useAuth0();


	useEffect(() => {
		(async function login() {

			if (!isLoading && !user && !isAuthenticated) { // Redirect to auth0 pop up and save the full url
				if (organizationId !== '') {
					await loginWithRedirect({ organization: organizationId });
					localStorage.setItem('url-fqt', window.location.href);
				}
			}
			else {
				/* Update store that user is autorized */
				dispatch(actions['common'].setAuthorized());
				/* Update store with user who has logged in*/
				if (user && user.name) {
					// dispatch(setUsername(user.name));
					dispatch(actions['common'].setUsername(user.name));
				}

				const getToken = async () => {
					try {
						const token = await getAccessTokenSilently({
							audience: 'https://' + config['AUTH0_DOMAIN'] + '/api/v2/',
							scope: 'fqt:general',
						});

						dispatch(actions['common'].setAuth0Token(token));
					} catch (e) {
						const err = cloneDeep(e);
						if (err.error === 'login_required' || err.error === 'consent_required') {
							await loginWithRedirect({ organization: organizationId });
						}

						console.log(err);
					}
				}

				getToken();
			}

			/* Little hack to prevent from infinity loop */
			if (isAuthenticated) {
				const getUrl = localStorage.getItem('url-fqt');
				if (getUrl !== null) {
					localStorage.removeItem('url-fqt');
					window.location.assign(getUrl); // Take the full url after the success log in trought auth0
				}
			}

		})();
		//eslint-disable-next-line
    }, [isLoading, isAuthenticated , organizationId]);

	useEffect(() => {
		(async function getOrgId() {
			if (config['REACT_APP_ENV'] !== 'local') {
				const response = await fetch(config['AUTH0_ORGANIZATION_LAMBDA']);
				if (!response.ok) {
					console.log('Failed to get organization id');
				}
				const organizationData = await response.json();
				setOrgId(organizationData.organizationId);
			} else {
				setOrgId(config['AUTH0_ORGANIZATION_ID']);
			}

			// if (organizationId) {
			dispatch(actions['common'].setOrganizationId(organizationId));
			//  }

		})();

		//eslint-disable-next-line
    }, []);

	return Object.keys(config).length > 0 && children// Return children if we have some props in config
};

export default AuthProvider;
