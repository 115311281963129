import { lazy } from "react";

const TennisTable = lazy(
    () => import("../components/sports/tabletennis/BoxWrapper"),
);

export const sportsMapping = {
    tabletennis: { component: TennisTable },
};

export const allowedSport = ["tabletennis"];
export const settings = ["releaseControl", "takeControl"];
export const allOverlayCommands = ["point", "gamescore"];

export const allUncfCommands = ["point"];
export const noTeamCommands = [
    "status",
    "status_ok",
    "status_unsure",
    "comment",
    "preMatchInfo",
    "attendance",
    "callcenterOk",
    "callcenterLost",
];

/*** Take needed props from the sport object
 * @param mobileAdmin - Either to display MA or CC for hyperlink directing to the call center of the app
 * @param verificationSteps - display how many ver steps will be displayed
 * @param callSr , @param mobileApp , @param stats  , @param mobileConnection , @param noCommands- show or not these Icons
 * @param report , @param viewer , @param lineup ,- show or not these Buttons
 */

export const sportFeatures = {
    tabletennis: {
        id: "269467",
        callcenterHyperlink: 9600,
        verificationSteps: 0,
        mobileConnection: false,
        communication: false,
        callSr: false,
        mobileApp: false,
        noCommands: false,
        stats: true,
        report: true,
        viewer: false,
        lineup: false,
        mobileAdmin: false,
        statuses: {
            gamestart: {
                0: { title: "Not Started" },
                1: { title: "Game 1" },
                2: { title: "Game 2" },
                3: { title: "Game 3" },
                4: { title: "Game 4" },
                5: { title: "Game 5" },
                6: { title: "Game 6" },
                7: { title: "Game 7" },
                8: { title: "Game 8" },
                9: { title: "Game 9" },
                100: { title: "Interrupted" },
                101: { title: "Abandoned" },
                111: { title: "Finished" },
            },
            gamefinish: {
                1: { title: "End Game 1" },
                2: { title: "End Game 2" },
                3: { title: "End Game 3" },
                4: { title: "End Game 4" },
                5: { title: "End Game 5" },
                6: { title: "End Game 6" },
                7: { title: "End Game 7" },
                8: { title: "End Game 8" },
                9: { title: "End Game 9" },
            },
        },
        colors: {
            callcenterOk: {
                background: "lightgreen",
            },
            callcenterLost: {
                background: "red",
            },
            status_ok: {
                background: "#82D769",
                color: "white",
            },
            status_unsure: {
                background: "red",
                color: "white",
            },
            firstServe: {
                background: "grey",
                color: "white",
            },
            gamestart: {
                background: "black",
                color: "white",
            },
            gamefinish: {
                background: "black",
                color: "white",
            },
            serve: {
                background: "#5B98F6",
                color: "white",
            },
            let: {
                background: "#ABCAFA",
                color: "white",
            },
            point: {
                background: "#6CE15B",
                color: "white",
            },
            gamescore: {
                background: "#6CE15B",
                color: "white",
            },
            timeout: {
                background: "#DA7834",
                color: "white",
            },
            preMatchInfo: {
                background: "#F86D8A",
                color: "white",
            },
            attendance: {
                background: "#F86D8A",
                color: "white",
            },
            homeColor1: {
                background: "#F86D8A",
                color: "white",
            },
            homeColor2: {
                background: "#F86D8A",
                color: "white",
            },
            awayColor2: {
                background: "#F86D8A",
                color: "white",
            },
            awayColor1: {
                background: "#F86D8A",
                color: "white",
            },
            comment: {
                background: "#5055FB",
                color: "white",
            },
        },
    },
    darts: {
        id: "8",
        callcenterHyperlink: 9600,
        verificationSteps: 0,
        mobileConnection: false,
        communication: false,
        callSr: false,
        mobileApp: false,
        noCommands: false,
        stats: true,
        report: false,
        viewer: false,
        lineup: false,
        mobileAdmin: true,
    },
    soccer: {
        id: "10",
        callcenterHyperlink: 9600,
        verificationSteps: 5,
        mobileConnection: false,
        communication: false,
        callSr: false,
        mobileApp: false,
        noCommands: true,
        stats: true,
        report: false,
        viewer: false,
        lineup: true,
        mobileAdmin: false,
    },
    basketball: {
        id: "4",
        callcenterHyperlink: 9600,
        verificationSteps: 0,
        mobileConnection: false,
        communication: false,
        callSr: false,
        mobileApp: false,
        noCommands: false,
        stats: true,
        report: false,
        viewer: false,
        lineup: false,
        mobileAdmin: true,
    },
    volleyball: {
        id: "91189",
        callcenterHyperlink: 9600,
        verificationSteps: 4,
        mobileConnection: false,
        communication: false,
        callSr: false,
        mobileApp: false,
        noCommands: false,
        stats: false,
        report: false,
        viewer: false,
        lineup: false,
        mobileAdmin: true,
    },
    beachvolleyball: {
        id: "91189" /* WRONG BEACHVOLLEY ID */,
        callcenterHyperlink: 9600,
        verificationSteps: 4,
        mobileConnection: false,
        communication: false,
        callSr: false,
        mobileApp: false,
        noCommands: false,
        stats: false,
        report: false,
        viewer: false,
        lineup: false,
        mobileAdmin: true,
    },
    icehockey: {
        id: "15",
        callcenterHyperlink: 9600,
        verificationSteps: 0,
        mobileConnection: false,
        communication: false,
        callSr: false,
        mobileApp: false,
        noCommands: false,
        stats: false,
        report: false,
        viewer: false,
        lineup: false,
        mobileAdmin: false,
    },
    baseball: {
        id: "3",
        callcenterHyperlink: 9600,
        verificationSteps: 0,
        mobileConnection: false,
        communication: false,
        callSr: false,
        mobileApp: false,
        noCommands: false,
        stats: false,
        report: false,
        viewer: false,
        lineup: true,
        mobileAdmin: false,
    },
    americanfootball: {
        id: "17",
        callcenterHyperlink: 9600,
        verificationSteps: 0,
        mobileConnection: false,
        communication: false,
        callSr: false,
        mobileApp: false,
        noCommands: false,
        stats: true,
        report: false,
        viewer: false,
        lineup: true,
        mobileAdmin: false,
    },
    futsal: {
        id: "491393",
        callcenterHyperlink: 9600,
        verificationSteps: 0,
        mobileConnection: false,
        communication: false,
        callSr: false,
        mobileApp: false,
        noCommands: false,
        stats: true,
        report: false,
        viewer: false,
        lineup: false,
        mobileAdmin: true,
    },
    handball: {
        id: "99614",
        callcenterHyperlink: 9600,
        verificationSteps: 0,
        mobileConnection: false,
        communication: false,
        callSr: false,
        mobileApp: false,
        noCommands: false,
        stats: false,
        report: false,
        viewer: false,
        lineup: false,
        mobileAdmin: true,
    },
    cricket: {
        id: "6",
        callcenterHyperlink: 9600,
        verificationSteps: 0,
        mobileConnection: false,
        communication: false,
        callSr: false,
        mobileApp: false,
        noCommands: false,
        stats: true,
        report: false,
        viewer: false,
        lineup: true,
        mobileAdmin: true,
    },
};
