import { RootState, CommonState } from "./types";
import cloneDeep from "lodash.clonedeep";
import { splitEventsFromURL } from "../common/utils";

const selectorsObj = {};

const eventsArray = splitEventsFromURL();

eventsArray.forEach((element) => {
    let eventId = element.event_id;
    selectorsObj[eventId] = {
        selectGameTimeState: (state: RootState) => state[eventId].gameTime,
        selectCurrentGameInfoState: (state: RootState) => {
            return {
                currentWeather: state[eventId].currentWeather,
                currentPreMatchInfo: state[eventId].currentPreMatchInfo,
                currentWind: state[eventId].currentWind,
                currentAttendance: state[eventId].currentAttendance,
                currentHomeColor1: state[eventId].currentHomeColor1,
                currentHomeColor2: state[eventId].currentHomeColor2,
                currentAwayColor1: state[eventId].currentAwayColor1,
                currentAwayColor2: state[eventId].currentAwayColor2,
            };
        },
        selectCloseOpenGame: (state: RootState) => state[eventId].currentCloseOpenGame,
        selectOverlayDisplay: (state: RootState) => state[eventId].currentOverlayDisplay,
        selectLogSection: (state: RootState) => state[eventId].currentLogSection,
        selectStatus: (state: RootState) => state[eventId].currentStatus,
        selectCallcenterLost: (state: RootState) => state[eventId].callcenterLost,
        selectCallcenterLostBool: (state: RootState) => state[eventId].callcenterLostBool,
        selectId: (state: RootState) => state[eventId].currentId,
        selectSequence: (state: RootState) => state[eventId].currentSequence,
        selectMessageType: (state: RootState) => state[eventId].currentMessageType,
        selectSound: (state: RootState) => state[eventId].sound,
        selectCommandLog: (state: RootState) => state[eventId].commandLog,
        selectInternalLog: (state: RootState) => state[eventId].internalLog,
        selectEventInfo: (state: RootState) => state[eventId].eventInfo,
        selectSport: (state: RootState) => state[eventId].sport,
        selectScore: (state: RootState) => state[eventId].score,
        selectSocket: (state: RootState) => state[eventId].socket,
        selectUncfScoreCounter: (state: RootState) => state[eventId].currentUncfScoreCounter,
    };
});

selectorsObj['common'] = {
    selectConnectionStatus: (state: CommonState) => state['common'].currentConnectionStatus,
    selectUser: (state: CommonState) => state['common'].user,
    selectAuthorized: (state: CommonState) => state['common'].authorized,
    selectConfig: (state: CommonState) => state['common'].config,
    selectUsername: (state: CommonState) => state['common'].username,
    selectToken: (state: CommonState) => state['common'].token
}

export const selectors = cloneDeep(selectorsObj);