import { AnyAction } from "redux";
import { Match } from "./types";
import cloneDeep from "lodash.clonedeep";
import { splitEventsFromURL } from "../common/utils";

const actionsObj = {};
const actionsTypesObj = {};

const eventsArray = splitEventsFromURL();

eventsArray.forEach((element) => {
    let eventId = element.event_id;

    actionsTypesObj[eventId] = {
        setGameTime: "setGameTime_" + eventId,
        stopClock: "stopClock_" + eventId,
        startClock: "startClock_" + eventId,
        setWeather: "setWeather_" + eventId,
        setPreMatchInfo: "setPreMatchInfo_" + eventId,
        setWind: "setWind_" + eventId,
        setAttendance: "setAttendance_" + eventId,
        setHomeColor1: "setHomeColor1_" + eventId,
        setHomeColor2: "setHomeColor2_" + eventId,
        setAwayColor1: "setAwayColor1_" + eventId,
        setAwayColor2: "setAwayColor2_" + eventId,
        setCloseOpenGame: "setCloseOpenGame_" + eventId,
        setOverlayDisplay: "setOverlayDisplay_" + eventId,
        setSuggestedStatus: "setSuggestedStatus_" + eventId,
        setLogSection: "setLogSection_" + eventId,
        setStatus: "setStatus_" + eventId,
        setCallcenterLost: "setCallcenterLost_" + eventId,
        setCallcenterLostBool: "setCallcenterLostBool_" + eventId,
        setId: "setId_" + eventId,
        setSequence: "setSequence_" + eventId,
        setMessageType: "setMessageType_" + eventId,
        setSound: "setSound_" + eventId,
        setCommandLog: "setCommandLog_" + eventId,
        setInternalLog: "setInternalLog_" + eventId,
        setEventInfo: "setEventInfo_" + eventId,
        setSport: "setSport_" + eventId,
        setScore: "setScore_" + eventId,
        setSocket: "setSocket_" + eventId,
        setUncfScoreCounter: "setUncfScoreCounter_" + eventId,
    };

    actionsObj[eventId] = {
        setGameTime: (gameTime?: number): AnyAction => ({
            type: actionsTypesObj[eventId].setGameTime,
            payload: gameTime ? gameTime : 1,
        }),

        stopClock: (): AnyAction => ({
            type: actionsTypesObj[eventId].stopClock,
            payload: false,
        }),

        startClock: (): AnyAction => ({
            type: actionsTypesObj[eventId].startClock,
            payload: true,
        }),

        setWeather: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setWeather,
            payload: value,
        }),

        setPreMatchInfo: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setPreMatchInfo,
            payload: value,
        }),

        setWind: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setWind,
            payload: value,
        }),

        setAttendance: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setAttendance,
            payload: value,
        }),

        setHomeColor1: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setHomeColor1,
            payload: value,
        }),

        setHomeColor2: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setHomeColor2,
            payload: value,
        }),

        setAwayColor1: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setAwayColor1,
            payload: value,
        }),

        setAwayColor2: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setAwayColor2,
            payload: value,
        }),

        setLogSection: (Match: Match): AnyAction => ({
            type: actionsTypesObj[eventId].setLogSection,
            payload: Match,
        }),

        setStatus: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setStatus,
            payload: value,
        }),
        
        setCallcenterLost: (value: number): AnyAction => ({
            type: actionsTypesObj[eventId].setCallcenterLost,
            payload: value,
        }),

        setCallcenterLostBool: (value: boolean): AnyAction => ({
            type: actionsTypesObj[eventId].setCallcenterLostBool,
            payload: value,
        }),
        
        setId: (value: number): AnyAction => ({
            type: actionsTypesObj[eventId].setId,
            payload: value,
        }),
        
        setSequence: (value: number): AnyAction => ({
            type: actionsTypesObj[eventId].setSequence,
            payload: value,
        }),

        setMessageType: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setMessageType,
            payload: value,
        }),

        setSound: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setSound,
            payload: value,
        }),

        setCommandLog: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setCommandLog,
            payload: value,
        }),

        setInternalLog: (value: any): AnyAction => ({
            type: actionsTypesObj[eventId].setInternalLog,
            payload: value,
        }),

        setEventInfo: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setEventInfo,
            payload: value,
        }),

        setSport: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setSport,
            payload: value,
        }),

        setScore: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setScore,
            payload: value,
        }),

        setSocket: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setSocket,
            payload: value,
        }),

        setUncfScoreCounter: (value: boolean): AnyAction => ({
            type: actionsTypesObj[eventId].setUncfScoreCounter,
            payload: value,
        }),

        /* ====================================== */
        /* American Football - sport specific  */

        setCloseOpenGame: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setCloseOpenGame,
            payload: value,
        }),

        setOverlayDisplay: (value: string): AnyAction => ({
            type: actionsTypesObj[eventId].setOverlayDisplay,
            payload: value,
        }),
    };
});

actionsTypesObj['common'] = {
    setConnectionStatus: "setConnectionStatus",
    setUser: "setUser",
    setAuthorized: "setAuthorized",
    setConfig: "setConfig",
    setUsername: "setUsername",
    setAuth0Token: "setAuth0Token",
    setOrganizationId: "setOrganizationId"
}

actionsObj['common'] = {
    setConnectionStatus: (value: boolean): AnyAction => ({
        type: actionsTypesObj['common'].setConnectionStatus,
        payload: value,
    }),
    setUser: (value: string): AnyAction => ({
        type: actionsTypesObj['common'].setUser,
        payload: value,
    }),
    setAuthorized: (): AnyAction => ({
        type: actionsTypesObj['common'].setAuthorized
    }),
    setConfig: (value: string) => ({
        type: actionsTypesObj['common'].setConfig,
        payload: value
    }),
    setUsername: (value: string ) => ({
        type: actionsTypesObj['common'].setUsername,
        payload: value
    }),
    setAuth0Token: (value: string ) => ({
        type: actionsTypesObj['common'].setAuth0Token,
        payload: value
    }),
    setOrganizationId: (value: string ) => ({
        type: actionsTypesObj['common'].setOrganizationId,
        payload: value
    })
}

export const actions = cloneDeep(actionsObj);
export const actionTypes = cloneDeep(actionsTypesObj);
