import { EventsInterface } from "../types";
import { allowedSport } from "./constants";

export const isValidMatch = (element) => {
    if (element !== "") {
        return true;
    }
    return false;
};

export const splitEventsFromURL = (testCount?: number): EventsInterface[] => {
    let result = [];
    const event_ids = new URLSearchParams(window.location.search).get(
        "event_ids",
    );

    if (event_ids !== null) {
        const eventsArray = event_ids.split(",");
        let allEventIds = [];

        eventsArray.forEach((element) => {
            if (isValidMatch(element)) {
                let eventData = element.split("_");
                /* Need to add sanity check for ID */
                if (
                    allowedSport.includes(eventData[0]) &&
                    !allEventIds.includes(eventData[1])
                ) {
                    /*  */
                    allEventIds.push(eventData[1]);
                    result.push({
                        sport: eventData[0],
                        event_id: eventData[1],
                    });
                }
            }
        });
    } else {
        // /* to simulate more than 8 matches */
        // if (testCount && testCount > 0) {
        //     result.push(
        //         { sport: "tabletennis", event_id: "test1" },
        //         { sport: "tabletennis", event_id: "test2" },
        //         { sport: "tabletennis", event_id: "test3" },
        //         { sport: "tabletennis", event_id: "test4" },
        //         { sport: "tabletennis", event_id: "test5" },
        //         { sport: "tabletennis", event_id: "test6" },
        //         { sport: "tabletennis", event_id: "test7" },
        //         { sport: "tabletennis", event_id: "test8" },
        //         { sport: "tabletennis", event_id: "test9" },
        //     );
        // } else {
        //     /* this is for unit testing purposes */
            result.push(
                { sport: "tabletennis", event_id: "testUnit" },
                { sport: "tabletennis", event_id: "unitTest" },
            );
        // }
    }

    return result;
};

export const removeEventFromURL = (event: string): string => {
    const event_ids = new URLSearchParams(window.location.search).get(
        "event_ids",
    );

    let url = new URL(window.location.href);
    let newList = event_ids?.replace(event, "");

    if (newList && newList[newList?.length - 1] === ",") {
        newList = newList.slice(0, -1);
    }
    if (newList && newList[0] === ",") {
        newList = newList.substring(1);
    }

    url.searchParams.set("event_ids", newList);

    return url.toString();
};

/* Update the correct Button Name */
export const closeOrOpenGame = (type) => {
    let temp_type = type;
    switch (type) {
        case "status_ok":
            temp_type = "openGame";
            break;
        case "status_unsure":
            temp_type = "closeGame";
            break;

        default:
            temp_type = "";
            break;
    }
    return (
        temp_type.substring(0, temp_type.length - 4) +
        " " +
        temp_type.substring(temp_type.length - 4, temp_type.length)
    );
};

export const getInitialScoreState = (sets) => {
    const initialScoreState = {};
    for (let i = 1; i <= sets; i++) {
        initialScoreState[i] = [0, 0];
    }
    return initialScoreState;
};

export const getGameScoreState = (sets, score) => {
    const initialOverallGameScore = [0, 0];

    for (let i = 1; i <= sets; i++) {
        if (
            score[i] &&
            score[i][0] > score[i][1] &&
            ((score[i][0] === 11 && Math.abs(score[i][0] - score[i][1]) >= 2) ||
                (score[i][0] > 11 && score[i][0] - score[i][1] === 2))
        ) {
            initialOverallGameScore[0]++;
        }

        if (
            score[i] &&
            score[i][1] > score[i][0] &&
            ((score[i][1] === 11 && Math.abs(score[i][1] - score[i][0]) >= 2) ||
                (score[i][1] > 11 && score[i][1] - score[i][0] === 2))
        ) {
            initialOverallGameScore[1]++;
        }
    }
    return initialOverallGameScore;
};

export const isAuthorized = async (lambdaPingHost: string) => {
    // console.log('in isAuthorized' , lambdaPingHost);
    const res = await fetch(`${lambdaPingHost}/lambda/Ping`, {
        credentials: "include",
    });
    // console.log('res = ' , res);

    return res.status === 200;
};

export const completeAuthorization = async (
    clientId: string,
    redirectURI: string,
    lambdaCookiesHost: string,
) => {
    const urlParams = new window.URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    const postParams = {
        grant_type: "authorization_code",
        client_id: clientId,
        redirect_uri: redirectURI,
        code: code,
    };

    const exchangeRes = await fetch(`${lambdaCookiesHost}/lambda/Cookies`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(postParams),
    });

    if (exchangeRes.status !== 200) {
        throw new Error();
    }

    window.location.assign(
        localStorage.getItem("parentUrl") !== null
            ? localStorage.getItem("parentUrl")
            : localStorage.getItem("url") !== null
            ? localStorage.getItem("url")
            : "/",
    );
};

export const fetchData = async (url: string) => {
    try {
        const data = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        return await data.json();
    } catch (e) {
        return null;
    }
};
