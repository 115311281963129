import React from "react";
import MonitoringTool from './components/common/MonitoringTool';
 // eslint-disable-next-line
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const App = () => {

    return (
        <div className="App" id="App" data-testid="App">
            <Switch>
                <Route exact path="/" render={() => <MonitoringTool test={false}/>} />
            </Switch>
        </div>
    );
}



export default App;
